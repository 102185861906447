.Item {
  display: flex;
  align-items: center;
  /* border: 1px solid #f0f0f3; */
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  min-width: 200px;
  max-width: 280px;
  overflow: hidden;
  text-align: left;
}

.Item2 {
  display: flex;
  align-items: center;
  /* border: 1px solid #f0f0f3; */
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  min-width: 150px;
  max-width: 280px;
  overflow: hidden;
  text-align: left;
}

.Item-resident {
  display: flex;
  /* align-items: center; */
  /* border: 1px solid #f0f0f3; */
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
}

.Item-compact {
  cursor: pointer;
  align-items: center;
}

.Item:hover,
.Item:hover,
.Item2:hover,
.Item2:hover * {
  color: var(--primary-color);
  background-color: #f3f3fa;
}

.Item-avatar {
  margin-right: 12px;
  min-width: 40px;
  object-fit: cover;
}

.Item-avatar2 {
  margin-right: 12px;
  min-width: 20px;
  object-fit: cover;
}

.Item-subtext {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.Item-subtext-resident {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: #838799;
}

.Form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  overflow: auto;
}

.Input-containers > div > input {
  width: 85%;
}

.Input-containers > input {
  width: 85%;
}

.Input-containers > button {
  width: 8%;
  padding: 15px;
  margin-left: 2%;
}

.Form-Align-Left {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow: auto;
}

.Form-control {
  display: flex;
  justify-content: center;
  width: 100%;
}

.SectionSeparator {
  width: 100%;
  max-width: calc(100% / 1.7 - 16px);
  min-width: calc(100% / 1.7 - 16px);
  height: 32px;
  margin-top: 24px;
  margin-bottom: 20px;
  text-align: left;
  border-bottom: 1px solid silver;
}

.SectionSeparator2 {
  width: 100%;
  max-width: calc(100% / 1 - 16px);
  min-width: calc(100% / 1 - 16px);
  height: 27px;
  margin-bottom: 24px;
  padding-bottom: 34px;
  text-align: left;
  border-bottom: 1px solid silver;
}

.Separator2Label {
  font-size: 20px;
  font-weight: 700;
}

.lists {
  position: absolute;
  width: 100%;
  max-height: 100px;
  top: 100%;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow-y: auto;
  border-radius: 5px;
}

.lists ul {
  padding: 0;
  margin: 0;
}

.lists ul li {
  padding: 8px 12px;
  list-style: none;
  cursor: pointer;
}

.lists ul li:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.Form .Input {
  margin-bottom: 32px;
  margin-right: 8px;
  margin-left: 8px;
  min-width: calc(100% / 1.7 - 16px);
  max-width: calc(100% / 1.7 - 16px);
  position: relative;
  text-align: left;
}

.Form input {
  padding: 12px;
}

.Form-Align-Left .Input {
  margin-bottom: 32px;
  margin-right: 8px;
  margin-left: 8px;
  max-width: calc(100% / 1 - 16px);
  min-width: calc(100% / 1 - 16px);
  position: relative;
  text-align: left;
}

.Form-Align-Left input {
  padding: 12px;
}

.Input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.Input-container {
  position: relative;
  display: flex;
  /* align-items: center; */
}

.Input-container .select {
  width: 100%;
  padding: 12px;
  margin-top: 3px;
  padding-right: 24px;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.5px;
  /* border-color: silver; */
  appearance: none;
  background-color: white;
  flex: 1;
}

.Input-container .select-2 {
  width: 100%;
  padding: 5px;
  margin-right: 8px;
  padding-right: 24px;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.5px;
  border-color: silver;
  appearance: none;
  background-color: white;
  font-size: 14px;
  flex: 1;
}

.Input-container select option:hover,
.Input-container select option:active,
.Input-container select option:checked,
.Input-container select option:focus {
  background: var(--primary-color);
  color: white;
}

.InputIcon {
  position: absolute;
  right: 8px;
  top: 14px;
  color: gray;
  margin-left: 16px;
}

.InputIcon-search {
  position: absolute;
  left: 4px;
  top: 3px;
  color: gray;
  margin-left: 8px;
}

.InputIcon-dashboard {
  position: absolute;
  right: 8px;
  top: 4px;
  color: gray;
  margin-left: 16px;
}

.Input-label {
  font-weight: bold;
}

.Input-sublabel {
  margin-left: 4px;
  color: grey;
  font-style: italic;
}

.Input-prefix,
.Input-suffix {
  border-radius: 4px 0px 0px 4px;
  border-top: 0.5px;
  border-bottom: 0.5px;
  border-right: 0.5px;
  border-left: 0.5px;
  border-color: #e9e9e9;
  border-style: solid;
  padding: 12px;
  background-color: #f3f3fa;
}

.Input-pretext {
  border: 0;
  padding: 12px;
  min-width: 60px;
}

.Input-prefix {
  border-right: 0px;
}

.Input-suffix {
  border-radius: 0px 4px 4px 0px;
  border-left: 0px;
}

.Input-options {
  background-color: white;
  border: 1px solid silver;
  border-radius: 4px;
  /* position: absolute; */
  /* max-width: 600px; */
  /* width: 100%; */
  /* margin-top: 80px;
  margin-bottom: 20px; */
  z-index: 2;
  max-height: 320px;
  overflow: auto;
}

.Input-optionItem {
  padding: 4px;
  cursor: pointer;
}

.Input-optionItem:hover {
  background-color: var(--primary-color);
  color: white;
}

.Input-error {
  color: crimson;
  margin-top: 4px;
  align-items: center;
  display: flex;
}

.error {
  border-color: crimson;
}

.MultiSelect {
  padding: 8px;
  padding-left: 8px;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.5px;
  border-color: silver;
  display: flex;
  flex-wrap: wrap;
}

.MultiSelectItem {
  display: flex;
  align-items: center;
  margin-right: 4px;
  margin-bottom: 4px;
  /* background-color: var(--primary-color); */
  border: 1px solid var(--primary-color);
  padding: 8px 12px;
  border-radius: 4px;
  color: var(--primary-color);
}

.MultiSelectItem svg {
  color: #3e414c;
  cursor: pointer;
  margin-left: 5px;
}

.MultiSelectItem-delete {
  /* margin-left: 4px; */
  cursor: pointer;
}

.EditorContainer {
  width: 100%;
  margin: 8px;
}

.Editor {
  padding: 8px;
  padding-left: 8px;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.5px;
  border-color: silver;
  /* display: flex; */
  width: 100%;
  /* height: 400px; */
  /* overflow: scroll; */
}

.EditorWrapper {
  height: 400px;
}

.Radio {
  margin-top: 8px;
}

.MultiSelectDropDown {
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-width: 0px;
  border-style: solid;
  border-color: #3e414c;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 4;
}

.MultiSelectDropDown li {
  padding: 12px 12px;
  display: flex;
}

.MultiSelectDropDown li span {
  flex-grow: 1;
}

.MultiSelectDropDown li svg {
  color: transparent;
}

.MultiSelectDropDown li[aria-selected="true"] {
  background-color: #fafafa;
  font-weight: 600;
}

.MultiSelectDropDown li[aria-selected="true"] svg {
  color: var(--color-primary);
}

.MultiSelectDropDown li[data-focus="true"] {
  background-color: #e6f7ff;
  cursor: pointer;
}

.MultiSelectDropDown li[data-focus="true"] svg {
  color: #3e414c;
}

.MultiSelectItemWrapper {
  display: flex;
  padding: 12px 0px 10px 0px;
  flex-wrap: wrap;
}

.time-pick {
  display: flex;
  position: absolute;
  top: 100%;
  max-height: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
  transition: 0.2s;
}

.time-pick.time-show {
  max-height: 400px;
}

.time-wrap {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.time-wrap::-webkit-scrollbar {
  display: none;
}

.time-wrap p {
  cursor: pointer;
}

.time-wrap p:hover {
  background-color: #377de654;
}

.time-wrap p.active {
  background-color: #2f78e9;
  color: white;
}

/* CSS TABLE STYLES */

.css-table {
  display: table;
  width: 100%;
  max-width: calc(100% / 1.7 - 16px);
}

.css-table-header {
  display: table-header-group;
  font-weight: bold;
  /* background-color: rgb(191, 191, 191); */
}

.css-table-body {
  display: table-row-group;
}

.css-table-row {
  display: table-row;
}

.css-table-header div,
.css-table-row div {
  display: table-cell;
  padding: 10px 6px;
}

.css-table-header div {
  text-align: center;
  border-bottom: 1px solid #3e414c;
}

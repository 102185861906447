.Button {
  background-color: var(--primary-color);
  min-width: 100px;
  /* max-width: 100px; */
  min-height: 2.4rem;
  border-radius: 6px;
  margin: 8px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  color: white;
}

.Button:disabled {
  background-color: #dddddd !important;
}

.ButtonNew {
  background-color: var(--primary-color) !important;
  min-width: 100px;
  /* max-width: 100px; */
  min-height: 2.4rem;
  border-radius: 6px;
  /* margin: 8px; */
  margin: 4px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.ButtonWizard {
  background-color: var(--primary-color) !important;
  min-width: 50px;
  /* max-width: 100px; */
  min-height: 2.4rem;
  border-radius: 6px;
  margin: 8px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.Button.Secondary {
  background-color: white !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.Button.Danger {
  background-color: white !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.Button.Download {
  background-color: #ffffff !important;
  color: #3e414c;
  border: 1px solid #e9e9e9 !important;
  font-weight: 500;
  transition: all 0.3s ease;
}

.Button.Download:hover {
  background-color: #dc2626 !important;
}

.ButtonWizard.Download {
  background-color: #ffffff !important;
  color: #3e414c !important;
  border: 1px solid #e9e9e9 !important;
  font-weight: 500;
}

.Button.Activated {
  background-color: #fde9ec !important;
  color: #e12029 !important;
}

.Button.Cancel {
  background-color: #6e757c !important;
  color: #ffffff !important;
  border: 1px solid #6e757c !important;
}

.Button.selected {
  background-color: var(--secondary-color);
}

.Button-label {
  margin-left: 8px;
}

.IconButton {
  background: none;
  padding-bottom: 0;
  color: grey;
  /* color: #113; */
}

.IconButton:hover {
  background: none;
  color: var(--accent-color);
}

.IconButton.inactive {
  color: silver;
  cursor: default;
}

.Button.inactive {
  color: grey !important;
  cursor: default !important;
  background-color: silver !important;
}

.IconButton.inactive:hover {
  background-color: white;
}

.Spinner {
  padding: 16px;
  display: flex;
  /* height: 100%; */
  align-items: center;
  justify-content: center;
}

.Filter {
  /* overflow: hidden; */
  width: 100%;
  text-align: center;
}

.List {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  /* max-height: 200px; */
  /* overflow-y: hidden; */
  margin-top: 4px;
  margin-bottom: 4px;
  /* width: calc(100% + 15px); */
  min-width: 200px;
}

.ListItem {
  margin-top: 4px;
  margin-bottom: 4px;
  width: 100%;
  color: var(--primary-color);
  background-color: white;
  border: 1px solid var(--primary-color);
}

.ListItem.load-more {
  border: none;
  outline: none;
}

.FullInput,
.ReactModalPortal .Input {
  width: 100%;
}

.MapForm {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.MapForm .Input {
  margin-left: 4px;
  margin-right: 4px;
}

.LabeledText {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
}

.Alert {
  background-color: var(--accent-color);
  color: var(--primary-color);
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .Alert .IconButton {
  color: var(--primary-color);
}

.Alert .IconButton:hover {
  color: var(--secondary-color);
} */

.Tab {
  display: flex;
  margin-bottom: 16px;
  width: 100%;
  height: 36px;
  border-bottom: 1px solid #f0f0f3;
  position: sticky;
  top: 0;
  z-index: 8;
  background-color: white;
}

.bgAds {
  background-image: url("../assets/BG_Ads.jpg");
  width: 100%;
  height: 30%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
}

.TabDashboard {
  display: flex;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 2px;
  width: 100%;
  height: 36px;
  /* border-bottom: 1px solid #f0f0f3; */
  position: sticky;
  top: 0;
  z-index: 8;
  background-color: white;
}

.TabItem {
  cursor: pointer;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.TabItemDashboard {
  cursor: pointer;
  width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TabItem-Text,
.TabItem-Text-inactive {
  font-size: 1.2rem;
  background: none;
  color: #113;
}

.TabItem-Text-inactive {
  color: grey;
}

.TabIndicator {
  height: 2px;
  border-radius: 4px;
  width: 100%;
  background-color: var(--primary-color);
}

.Logo {
  max-height: 200px;
}

.Group,
.GroupActive {
  padding: 8px;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-bottom: 8px;
  text-align: center;
  cursor: pointer;
}

.Group:hover {
  background-color: var(--primary-color);
  color: white;
}

.Group.blue:hover {
  background-color: #244091 !important;
  color: white;
}

.GroupActive {
  background-color: var(--primary-color);
  color: white;
}

.Group2,
.Group2Active {
  padding: 8px 12px 8px 12px;
  border: 0 solid #eee;
  border-radius: 6px;
  margin-bottom: 8px;
  text-align: center;
  cursor: pointer;
  background: #ffffff;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.Group2:hover {
  background-color: #244091;
  color: white;
}

.Group2Active {
  background-color: #244091;
  color: white;
}

input[type="button"]:disabled {
  background-color: var(--gray);
  cursor: default;
}

.HighlightValue-Red {
  font-weight: bold;
  color: var(--red);
}

.Breadcrumb {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 14px;
  /* margin-left: 18px; */
  font-size: 14px;
  color: var(--secondary-color);
  font-weight: 500;
}

.search-input {
  position: relative;
}

.search-input input {
  width: 100%;
  padding-right: 2rem;
}

.search-input label {
  height: 100%;
  position: absolute;
  margin: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
}

.search-input svg {
  font-size: 1.2rem;
  color: grey;
}

.custom-options {
  padding: 0;
  position: absolute;
  background-color: white;
  width: 100%;
  left: 0;
  top: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 6px rgba(0, 19, 37, 0.116);
  margin-top: 0.6em;
  margin-bottom: 0;
  overflow: hidden;
  max-height: 0px;
  z-index: 3;
  transition: height 0.3s ease, max-height 0.3s ease;
}

.custom-options.to-down {
  overflow-y: auto;
  max-height: 200px;
  margin-bottom: 2em;
}

.no-list {
  list-style: none;
  text-transform: capitalize;
}

.selectable {
  cursor: pointer;
}

.no-list.selectable:hover {
  background-color: var(--accent-color);
  color: white;
}

.icon {
  position: absolute;
  right: 0.7em;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2em;
  transition: 0.2s;
}

.icon.up {
  transform: rotate(180deg) translateY(50%);
}

.radio-boxes {
  position: relative;
  width: auto;
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.068); */
  border: 1px solid #ced4da;
  border-radius: 6px;
  overflow: hidden;
}

.radio-indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 33.3333%;
  border-radius: 4px;
  z-index: 1;
  transition: 0.4s cubic-bezier(0.54, 0.11, 0.07, 1.14);
}

/* .radio-indicator.emergency {
  background-color: #dc3545;
  left: 73%;
} */
.radio-indicator.high {
  background-color: #ffc107;
  left: 66.6666%;
}
.radio-indicator.normal {
  background-color: #28a745;
  left: 33.33333%;
}
.radio-indicator.low {
  background-color: #28a745;
  left: -1%;
}

@media only screen and (max-width: 575px) {
  .radio-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 33.33333%;
    border-radius: 6px;
    z-index: 1;
    transition: 0.4s cubic-bezier(0.54, 0.11, 0.07, 1.14);
  }

  /* .radio-indicator.emergency {
    background-color: #dc3545;
    top: 0%;
    left: 0;
  } */
  .radio-indicator.high {
    background-color: #ffc107;
    top: 0%;
    left: 0;
  }
  .radio-indicator.normal {
    background-color: #28a745;
    top: 33.33333%;
    left: 0;
  }
  .radio-indicator.low {
    background-color: #28a745;
    top: 66.66666%;
    left: 0;
  }
}

.radio-box input {
  opacity: 0;
}

.radio-box label {
  margin: 0;
  position: relative;
  padding: 8px 12px;
  /* background-color: white; */
  cursor: pointer;
  min-width: 100px;
  text-transform: uppercase;
  color: rgba(0, 19, 37, 0.513);
  font-weight: bold;
  border-radius: 6px;
  transition: 0.4s;
  z-index: 2;
}

/* .radio-box label.emergency {
  background-color: #dc3545;
  color: white;
  transition: .1s;
} */
.radio-box label.high {
  /* background-color: #ffc107; */
  color: #3e414c;
  transition: 0.1s;
}
.radio-box label.normal {
  /* background-color: #28a745; */
  color: white;
  transition: 0.1s;
}
.radio-box label.low {
  /* background-color: #28a745; */
  color: white;
  transition: 0.1s;
}

.switch-box {
  width: 150px;
  height: 50px;
  position: relative;
}

.switch-box label {
  margin: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 4px 0 #d8d8d8;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.1s;
}

.switch-box label:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 #d8d8d8;
}

.switch-box input {
  opacity: 0;
}

.switch-on label {
  background-color: var(--primary-color);
  color: white;
  box-shadow: 0 4px 0 #bbbbbb;
}

.switch-off label {
  background-color: #c0c0c0;
  color: white;
}

.border-right-light {
  border-right: 0.9px solid rgba(255, 255, 255, 0.486);
}

.modal-hover {
  position: relative;
}

.list-modal-hover {
  position: absolute;
  top: 100%;
  left: 90%;
  border-radius: 3px;
  background-color: white;
  border: 1px solid var(--primary-color);
  display: none;
  min-width: 250px;
  text-align: left;
}

.list-modal-hover.on {
  display: block;
}

.list-modal-hover-wizard {
  position: absolute;
  padding: 10px 20px 10px 20px;
  z-index: 1;
  border-radius: 8px;
  background-color: hsla(0, 0%, 35%, 0.9);
  display: none;
  text-align: center;
  min-width: 200px;
  animation: fadeIn 1s;
}

.list-modal-hover-wizard::after {
  content: "";
  position: absolute;
  bottom: 100%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent hsla(0, 0%, 35%, 0.9) transparent;
}

.list-modal-hover-wizard.center {
  left: 50%;
  margin-left: -100px;
}

.list-modal-hover-wizard.center::after {
  left: 50%;
  margin-left: -5px;
}

.list-modal-hover-wizard.left {
  right: 50%;
}

.list-modal-hover-wizard.left::after {
  right: 5%;
  margin-right: -5px;
}

.list-modal-hover-wizard.right {
  left: 0;
}

.list-modal-hover-wizard.right::after {
  left: 5%;
  margin-left: -5px;
}

.list-modal-hover-wizard.on {
  display: block;
}

.primary-color {
  background-color: var(--primary-color);
  color: white;
}

.radio-booking {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
.radio-booking + img {
  cursor: pointer;
}

/* CHECKED STYLES */
.radio-booking:checked + img {
  outline: 2px solid #e12029;
  border-radius: 8px;
}

.radio-voucher {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
.radio-voucher + div {
  cursor: pointer;
  border-radius: 8px;
  padding: 14px 20px 14px 20px;
  border: 1px solid rgba(233, 233, 233, 1);
}

/* CHECKED STYLES */
.radio-voucher:checked + div {
  outline: 1px solid #e12029;
  border-radius: 8px;
  padding: 14px 20px 14px 20px;
  max-width: 300px;
}

.radio-voucher-box {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* RADIO STYLES DISCOUNT TYPE */
.radio-voucher-box + div {
  cursor: pointer;
  padding: 10px 20px 10px 20px;
  outline: 1px solid #e9e9e9;
  opacity: 60%;
  border-radius: 8px;
}

/* CHECKED STYLES DISCOUNT TYPE */
.radio-voucher-box:checked + div {
  background: #fde9ec;
  color: #e12029;
  outline: 1px solid #e12029;
  border-radius: 8px;
  padding: 10px 20px 10px 20px;
  opacity: 100%;
}

.wizard-header {
  font-size: 20px;
  font-weight: 700;
}

.wizard-title {
  font-size: 24px;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 10px;
}

.wizard-body {
  font-size: 14px;
  font-weight: 400;
  animation: fadeIn 1s;
}

.wizard-title-container {
  margin: 5px;
  font-size: 16px;
  font-weight: 700;
}

.wizard-body-container {
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
}

.wizard-title-container-bil {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.wizard-body-container-bil {
  font-size: 10px;
  font-weight: 400;
  color: #5a5a5a;
  text-align: center;
}

.wizard-step-body {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #3e414c;
}

.wizard-step-body-icon {
  color: #3e414c;
}

.wizard-step-body-inactive {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #c1c9d2;
}

.wizard-step-body-icon-inactive {
  color: #c1c9d2;
}

.Container-wizard {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex: 1;
  margin-top: 30px;
  margin-right: 30px;
}

.Container-wizard-bil {
  background-color: white;
  padding: 20px 10px 20px 10px;
  border-radius: 8px;
  display: flex;
  flex: 1;
  margin-top: 30px;
  max-width: 200px;
  min-width: 190px;
  min-height: 90px;
  max-height: 100px;
}

.border-wizard {
  border: 2px solid #e9e9e9;
  border-radius: 8px;
}

.border-wizard:hover {
  border: 2px solid #e12029;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.border-wizard-bil {
  border: 2px solid #e9e9e9;
  border-radius: 8px;
}

.border-wizard-bil:hover {
  border: 2px solid #e12029;
  border-radius: 8px;
  cursor: pointer;
}

.Container-step-wizard {
  background-color: white;
  padding: 0;
  display: flex;
  flex: 1;
}

.border-step-wizard {
  border-top: 4px solid #e12029;
}

.border-step-wizard-inactive {
  border-top: 4px solid #c1c9d2;
}

.border-step-wizard-done {
  border-top: 4px solid #3e414c;
}

.special_modal .modal-content {
  border-radius: 10px;
  border: 0px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.info-modal-hover {
  position: absolute;
  right: 0px;
  top: 0px;
}

.truncate-multiline-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Auth {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #e75f49; */
}

.Auth-center {
  background-color: #ffffff;
  min-width: 360px;
  /* width: 40%; */
  /* height: 30vh; */
  padding: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
}

.Auth-label {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
}

.Auth-input {
  /* font-size: 1.3rem; */
  /* margin: 20px; */
  text-align: left;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #e12029;
  /* --primary-color: #244091; */
  /* --primary-color: #2f78e9; */
  --secondary-color: #838799;
  /* --secondary-color: rgba(0, 116, 255, 1); */
  --primary-yellow-color: rgba(250, 219, 8, 1);
  --accent-color: #e12029;
  /* --accent-color: #2f78e9; */
  --light-black: #838799;
  font-size: 12px;
  font-family: "Noto Sans", sans-serif;
  --background-color: #f3f3fa;
  background-color: #f3f3fa;
  /* background-color: rgba(245, 245, 245, 1); */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}

ul {
  @apply list-disc;
}

li {
  @apply mb-[0.2rem];
}

.text-primary {
  color: var(--primary-color) !important;
}

.list-group-item.active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white !important;
}

.list-group-item.active * {
  color: white !important;
}

.badge-primary {
  background-color: var(--primary-color);
}

.scroller-y {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #afb3b9 #f1f1f1;
  scrollbar-width: none;
}
.scroller-x {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: #afb3b9 #f1f1f1;
  scrollbar-width: none;
}
.scroller {
  overflow: auto;
  scrollbar-color: #afb3b9 #f1f1f1;
  scrollbar-width: none;
}

/* width */
::-webkit-scrollbar {
  width: none;
  /* height: 6px; */
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1;
  border-radius: 20px; */
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb:vertical {
  /* background: #afb3b9;
  border-radius: 20px; */
  /* width: 1px; */
  scrollbar-width: none;
}
::-webkit-scrollbar-thumb:horizontal {
  /* background: #d0d4da;
  border-radius: 20px;
  width: 1px; */
  width: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #989da1;
}

body {
  margin: 0;
  font-size: 14px;
  font-family: "Noto Sans", sans-serif;
  background-color: #f3f3fa;
  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
    */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3e414c;
}

body::-webkit-scrollbar {
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label {
  margin: 4px;
  margin-left: 0;
}

p,
a {
  margin: 0;
  text-align: justify;
  text-decoration: none;
  color: #3e414c;
}
a:hover {
  color: var(--primary-color);
  text-decoration: none;
}

button {
  font-size: 1rem;
  font-family: "Noto Sans", sans-serif;
  /* cursor: pointer; */
  background-color: var(--primary-color);
  border-radius: 4px;
  /* color: white; */
  border: none;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

input,
textarea {
  /* margin-top: 3px; */
  /* padding: 6px; */
  padding: 12px 24px 12px 12px;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.5px;
  border-color: #e9e9e9;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Noto Sans", sans-serif;
}

.withaddons {
  border-radius: 4px 0px 0px 4px;
}

.addons {
  border-radius: 0px 4px 4px 0px;
  border-top: 0.5px;
  border-bottom: 0.5px;
  border-right: 0.5px;
  border-left: 0px;
  border-color: silver;
  border-style: solid;
  /* padding: 6px; */
  padding: 12px;
  /* margin-top: 3px; why? */
  background-color: #f3f3fa;
}

input[type="button"] {
  background-color: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
}

input[type="file"] {
  border: none;
  padding: 4px;
  margin-left: 12px;
}

.list-autocomplete {
  position: absolute;
  padding: 0;
  top: 100%;
}

.list-autocomplete li {
  list-style: none;
}

h5 {
  /* margin: 0 0 2rem 0; */
  font-weight: 600;
}

.red {
  color: red;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

th {
  padding: 8px;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 16px;
  text-align: left;
  border-top: 1px solid #e5e7e9;
  border-bottom: 1px solid #e5e7e9;
  position: sticky;
  top: 0;
  background-color: #f4f4f4;
  z-index: 1;
}

td {
  border-bottom: 1px solid #e5e7e9;
  padding: 8px;
  padding-left: 16px;
  padding-bottom: 12px;
  padding-top: 12px;
}

th:last-child,
td:last-child {
  border-right: 0;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}

form {
  text-align: center;
}

.shadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
}

.validation-error {
  display: block;
  /* margin: 4px 0 8px 8px; */
  color: rgb(235, 76, 76);
  font-size: 13px;
}

.shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 400px;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.NoDecorationLink div {
  text-decoration: none;
}

.Link {
  color: var(--primary-color);
  cursor: pointer;
}

.Link:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-pointer * {
  cursor: pointer;
}

.btn-secondary {
  border: none;
}

.btn-custom {
  background-color: white !important;
  border: 1px solid red !important;
  color: red !important;
}

.btn-cancel {
  background-color: #f4f4f4 !important;
  color: #3e414c !important;
}

.react-confirm-alert-body {
  width: auto !important;
}

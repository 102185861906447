.MessageContainer,
.MessageContainer-own {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}

.MessageContainer-own {
  flex-direction: row-reverse;
}

.Message,
.Message-own {
  background-color: white;
  font-size: 12px;
  padding: 8px 12px 8px 12px;
  border-radius: 0 6px 6px 6px;
  margin: 4px;
  max-width: 500px;
  color: #3E414C;
}

.Message-own {
  /* background-color: var(--primary-color); */
  /* color: white; */
  border-radius: 6px 0 6px 6px;
}

.Message-attached {
  background-color: white;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  border-radius: 6px;
  border: 1px solid #E5E7E9;
  margin: 4px;
  max-width: 500px;
  color: #3E414C;
}

.MessageAvatar {
  max-height: 2rem;
  width: 2rem;
}

.MessageUsername {
  margin-left: 8px;
  margin-right: 8px;
  font-weight: bold;
}

.MessageTime {
  font-size: 0.8rem;
  display: flex;
  align-items: flex-end;
}

.MessageTimeNow{
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  padding-top: 8px;
}

.Participant {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px;
  margin-bottom: 4px;
}

.Room {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 8px;
  min-height: 100px;
  border-bottom: 1px solid #f0f0f3;
  cursor: pointer;
}

.Room:hover {
  background-color: #f0f0f3;
}

.Room.selected {
  background-color: #f0f0f3;
}

.Room-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.Room-right {
  background-color: var(--primary-color);
  color: white;
  width: 20px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}

.Room-title {
  display: flex;
  flex-direction: column;
}

.Room-name {
  font-weight: bold;
}

.Chat-title {
  font-size: 14px;
  font-weight: 700;
}

.Chat-subtitle {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.Room-subtitle {
  font-size: small;
  text-align: left;
}

.Room-message {
  color: #3E414C;
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Room-unread {
  color: white;
}

.Room-container-info {
  color: #838799 !important;
  background: #FFFFFF;
  border: 1px solid #E5E7E9;
  margin-top: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  cursor: default;
}

.Room-gray-text {
  color: #838799 !important;
  font-weight: 500;
}
